import { connections } from './lib/fivetran/connections';
import { Subscription } from './lib/organizations/types/organization-subscription';

export const defaultLimits = {
  chatbot: 1,
  isFree: true,
  appsAllowed: true,
  wordCount: 500000,
  webpage: 500,
  message: 50,
  appsAllowedList: ['google_sheets'],
};

export const productsTest = [
  {
    name: 'Starter',
    description: '16% discount',
    features: [
      '3 chatbots',
      '1,000 chat queries /month',
      '0.5M words / 500 web pages per chatbot',
      'Upload multiple files',
      'API Access',
    ],
    limits: {
      chatbot: 3,
      isFree: false,
      appsAllowed: true,
      wordCount: 500000,
      webpage: 500,
      message: 1000,
      appsAllowedList: ['google_sheets'],
    },
    plans: [
      {
        name: 'Monthly',
        price: '$29',
        stripePriceId: 'price_1NJ8nsHsdqP07xgbv8JoEhAH',
      },
      {
        name: 'Yearly',
        price: '$299',
        stripePriceId: 'price_1NJ8EMHsdqP07xgbVFxGi0fk',
      },
    ],
  },
  {
    name: 'Growth',
    badge: `Most Popular`,
    recommended: true,
    description: '19% discount',

    features: [
      '6 chatbots',
      '5,000 chatbot queries /month',
      '2M words / 2000 web pages per chatbot',
      // 'Connect to Applications / DBs',
      'Create chatbots on top of tables',
      'Upload multiple files',
      'API Access',
    ],
    limits: {
      chatbot: 6,
      isFree: false,
      appsAllowed: true,
      wordCount: 2000000,
      webpage: 2000,
      message: 5000,
      appsAllowedList: ['google_sheets', 'zendesk', 'one_drive', 'jira', 'typeform'],
    },
    plans: [
      {
        name: 'Monthly',
        price: '$99',
        stripePriceId: 'price_1NJ8orHsdqP07xgbW1VTSJtY',
      },
      {
        name: 'Yearly',
        price: '$999',
        stripePriceId: 'price_1NJ8pGHsdqP07xgb7xLS7wS3',
      },
    ],
  },
  {
    name: 'Scale',
    badge: ``,
    recommended: false,
    description: '20% discount',
    limits: {
      chatbot: 10,
      isFree: false,
      appsAllowed: true,
      wordCount: 5000000,
      webpage: 3000,
      message: 20000,
      appsAllowedList: ['google_sheets', 'zendesk', 'one_drive', 'jira', 'typeform'],
    },
    features: [
      '10 chatbots',
      '20,000 chatbot queries /month',
      '5M words / 5000 web pages per chatbot',
      // 'Connect to Applications / DBs',
      'Create chatbots on top of tables',
      'Upload multiple files',
      'API Access',
    ],
    plans: [
      {
        name: 'Monthly',
        price: '$499',
        stripePriceId: 'price_1NJ8qIHsdqP07xgbcvCfete3',
      },
      {
        name: 'Yearly',
        price: '$4999',
        stripePriceId: 'price_1NJ8t4HsdqP07xgbFo3fZARt',
      },
    ],
  },
  {
    name: 'Enterprise',
    description: 'unlimited chatbots',
    badge: ``,
    features: [
      'Unlimited chatbots',
      ' Unlimited chatbot queries /month',
      'Unlimited words /chatbot',
      'Connect to Applications / DBs',
      'Create chatbots on top of tables',
      'Upload multiple files',
      'API Access',
    ],
    plans: [
      {
        name: '',
        price: 'Contact us',
        stripePriceId: '',
        label: `Contact us`,
        href: `/contact`,
        trialPeriodDays: 0,
      },
    ],
  },
];

export const productsProd = [
  {
    name: Subscription.Starter,
    description: '16% discount',
    features: [
      '3 chatbots',
      '1,000 chat queries /month',
      '0.5M words / 500 web pages per chatbot',
      'Upload multiple files',
      'API Access',
    ],
    limits: {
      chatbot: 3,
      isFree: false,
      appsAllowed: true,
      wordCount: 500000,
      webpage: 500,
      message: 1000,
      appsAllowedList: ['google_sheets'],
    },
    plans: [
      {
        name: 'Monthly',
        price: '$29',
        stripePriceId: 'price_1NJ8DgHsdqP07xgbGrtdwjps',
      },
      {
        name: 'Yearly',
        price: '$299',
        stripePriceId: 'price_1NJ8EMHsdqP07xgbVFxGi0fk',
      },
    ],
  },
  {
    name: Subscription.Growth,
    badge: `Most Popular`,
    recommended: true,
    description: '19% discount',

    features: [
      '6 chatbots',
      '5,000 chatbot queries /month',
      '2M words / 2000 web pages per chatbot',
      // 'Connect to Applications / DBs',
      'Create chatbots on top of tables',
      'Upload multiple files',
      'API Access',
    ],
    limits: {
      chatbot: 6,
      isFree: false,
      appsAllowed: true,
      wordCount: 2000000,
      webpage: 2000,
      message: 5000,
      appsAllowedList: ['google_sheets', 'zendesk', 'one_drive', 'jira', 'typeform'],
    },
    plans: [
      {
        name: 'Monthly',
        price: '$99',
        stripePriceId: 'price_1NJ8HcHsdqP07xgbLtJN6Ik9',
      },
      {
        name: 'Yearly',
        price: '$999',
        stripePriceId: 'price_1NJ8IFHsdqP07xgbScJU81uT',
      },
    ],
  },
  {
    name: Subscription.Scale,
    badge: ``,
    recommended: false,
    description: '20% discount',
    limits: {
      chatbot: 10,
      isFree: false,
      appsAllowed: true,
      wordCount: 5000000,
      webpage: 3000,
      message: 20000,
      appsAllowedList: ['google_sheets', 'zendesk', 'one_drive', 'jira', 'typeform'],
    },
    features: [
      '10 chatbots',
      '20,000 chatbot queries /month',
      '5M words / 5000 web pages per chatbot',
      // 'Connect to Applications / DBs',
      'Create chatbots on top of tables',
      'Upload multiple files',
      'API Access',
    ],
    plans: [
      {
        name: 'Monthly',
        price: '$499',
        stripePriceId: 'price_1NJ8LhHsdqP07xgb8hi8Hy5p',
      },
      {
        name: 'Yearly',
        price: '$4999',
        stripePriceId: 'price_1NJ8NfHsdqP07xgbzvGXUCbW',
      },
    ],
  },
  {
    name: Subscription.Enterprise,
    description: 'unlimited chatbots',
    badge: ``,
    features: [
      'Unlimited chatbots',
      ' Unlimited chatbot queries /month',
      'Unlimited words /chatbot',
      'Connect to Applications / DBs',
      'Create chatbots on top of tables',
      'Upload multiple files',
      'API Access',
    ],
    plans: [
      {
        name: '',
        price: 'Contact us',
        stripePriceId: '',
        label: `Contact us`,
        href: `/contact`,
        trialPeriodDays: 0,
      },
    ],
  },
];

export const enterprise = [
  {
    name: 'Enterprise',
    limits: {
      chatbot: 1000,
      isFree: false,
      appsAllowed: true,
      wordCount: 1000000000,
      webpage: 5000,
      message: 1000000000,
      appsAllowedList: [...connections.map((connection) => connection.id)],
    },
    unlimited: {
      isChatbotUnlimited: true,
      isWordCountUnlimited: true,
      isWebpageUnlimited: true,
      isMessageUnlimited: true,
    },
    seats: 6,
  },
  {
    name: 'Enterprise 1',
    limits: {
      chatbot: 1000,
      isFree: false,
      appsAllowed: true,
      wordCount: 1000000000,
      webpage: 5000,
      message: 1000000000,
      appsAllowedList: [...connections.map((connection) => connection.id)],
    },
    unlimited: {
      isChatbotUnlimited: true,
      isWordCountUnlimited: true,
      isWebpageUnlimited: true,
      isMessageUnlimited: true,
    },
    organizations: ['1', '8'] as string[],
    seats: 6,
  },
  {
    name: 'Enterprise for kseniia.pavlenko+4738@wand.ai and pavlo',
    limits: {
      chatbot: 1000,
      isFree: false,
      appsAllowed: true,
      wordCount: 100000000,
      webpage: 5000,
      message: 1000000,
      appsAllowedList: [...connections.map((connection) => connection.id)],
    },
    unlimited: {
      isChatbotUnlimited: true,
      isWordCountUnlimited: true,
      isWebpageUnlimited: true,
      isMessageUnlimited: true,
    },
    organizations: ['37', '36'] as string[],
    seats: 5,
  },
  {
    name: 'Enterprise for kseniia.pavlenko+9@wand.ai',
    limits: {
      chatbot: 1000,
      isFree: false,
      appsAllowed: true,
      wordCount: 100000000,
      webpage: 5000,
      message: 1000000,
      appsAllowedList: [...connections.map((connection) => connection.id)],
    },
    unlimited: {
      isChatbotUnlimited: true,
      isWordCountUnlimited: true,
      isWebpageUnlimited: true,
      isMessageUnlimited: true,
    },
    organizations: ['13'] as string[],
  },
];

export const enterpriseDevelop = [
  {
    name: 'Enterprise',
    limits: {
      chatbot: 1000,
      isFree: false,
      appsAllowed: true,
      wordCount: 1000000000,
      webpage: 5000,
      message: 1000000000,
      appsAllowedList: [...connections.map((connection) => connection.id)],
    },
    unlimited: {
      isChatbotUnlimited: true,
      isWordCountUnlimited: true,
      isWebpageUnlimited: true,
      isMessageUnlimited: true,
    },
    seats: 6,
  },
  {
    name: 'Wand Enterprise',
    limits: {
      chatbot: 1000,
      isFree: false,
      appsAllowed: true,
      wordCount: 1000000000,
      webpage: 5000,
      message: 1000000000,
      appsAllowedList: [...connections.map((connection) => connection.id)],
    },
    unlimited: {
      isChatbotUnlimited: true,
      isWordCountUnlimited: true,
      isWebpageUnlimited: true,
      isMessageUnlimited: true,
    },
    organizations: ['1'] as string[],
    seats: 5,
  },
  {
    name: 'Enterprise for kseniia.pavlenko+4738@wand.ai and pavlo',
    limits: {
      chatbot: 1000,
      isFree: false,
      appsAllowed: true,
      wordCount: 100000000,
      webpage: 5000,
      message: 1000000,
      appsAllowedList: [...connections.map((connection) => connection.id)],
    },
    unlimited: {
      isChatbotUnlimited: true,
      isWordCountUnlimited: true,
      isWebpageUnlimited: true,
      isMessageUnlimited: true,
    },
    organizations: ['37', '36'] as string[],
    seats: 5,
  },
  {
    name: 'Enterprise for kseniia.pavlenko+9@wand.ai',
    limits: {
      chatbot: 1000,
      isFree: false,
      appsAllowed: true,
      wordCount: 100000000,
      webpage: 5000,
      message: 10000000,
      appsAllowedList: [...connections.map((connection) => connection.id)],
    },
    unlimited: {
      isChatbotUnlimited: true,
      isWordCountUnlimited: true,
      isWebpageUnlimited: true,
      isMessageUnlimited: true,
    },
    organizations: ['13'] as string[],
  },
];
